

export function formatCurrency(amount: number, currency: string, decimalPlaces?:number) : string {
    return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency:currency,
        minimumFractionDigits: decimalPlaces ?? 2,
        maximumFractionDigits: decimalPlaces ?? 2
    }).format(amount);
}
