import { Component, EventEmitter, Input, Output } from '@angular/core';
import {  Inventory } from '@models/index';
import { formatCurrency } from '@utils/shared/shared';

@Component({
  selector: 'app-cart-item',
  standalone: true,
  imports: [],
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.css'
})
export class CartItemComponent {
  @Input() cartItem: any;
  @Input() currency: string | undefined = '';
  @Input() cart: Inventory[] = [];
  @Output() updateQuantityEmit = new EventEmitter<{ cartItem: Inventory, increment: boolean; }>(); 
  @Output() cartItemEmitted = new EventEmitter<Inventory>();

  removeItem(cart?: Inventory) {
    this.cartItemEmitted.emit(cart);
  }

  formatCurrency = formatCurrency;

  updateQuantity(increment:boolean, cartItem:Inventory){
    this.updateQuantityEmit.emit({cartItem,increment});
  }
}
