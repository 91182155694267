<div class="flex flex-col space-y-3">
  <div class="flex justify-between">
    <span class="font-bold">My Cart {{this.cartCount > 0 ? (this.cart.length > 1 ? (this.cart.length+' orders') :
      (this.cartCount+' item(s)')
      ) : '' }} </span>
    <span>
      @if(this.cart.length > 0){
      <i (click)="clearCartEvent()" class="text-xl pi pi-trash"></i>
      }
    </span>
  </div>

  <div class="flex max-h-[300px] flex-col overflow-auto">
    @for(orderCart of cart; track orderCart; let i = $index){
    @if(this.cart.length > 1){
    <span class="font-semibold">Order {{ i+ 1}} ({{ orderCart.length }})</span>
    }
    @for(orderItem of orderCart; track orderItem.id){
    <app-cart-item [currency]="outlet?.currency" (updateQuantityEmit)="updateCartQuantityEvent($event)"
      (cartItemEmitted)="removeFromCartEvent($event, i)" [cartItem]="orderItem"></app-cart-item>
    }
    }
  </div>
  @if(cartCount > 0){
  <app-delivery-option [uniqueId]="uniqueId" [enableDelivery]="this.outlet?.delivery" [selectedDeliveryMode]="selectedDeliveryMode"
    (selectedDeliveryModeEmitter)="deliveryModeChangedEvent($event)"></app-delivery-option>
  <div class="space-y-1">
    <span class="flex font-semibold">Item(s) Cost : {{ formatCurrency(ordersCost, 'NGN') }}</span>
    <span class="flex font-semibold">Service Charge : {{ formatCurrency(serviceCharge, 'NGN') }}</span>
    @if(this.outlet?.delivery && this.selectedDeliveryMode === 1){
    <span class="flex font-semibold">Delivery Cost : {{ formatCurrency(deliveryFee, 'NGN')  }} ({{this.deliveryLocation}})</span>
    }
  </div>
  }
  <div class="form_div">
    @if(cartCount > 0){
    <button (click)="newOrderEvent()" style="background-color: #E7E8EA"
      class="flex justify-center p-3 space-x-4 duration-300 create_button text-vendblocblue hover:shadow-lg"
      type="submit">
      <span>New Order</span>
    </button>
    <button (click)="checkoutEvent()" style="background-color: #0f172a"
      class="flex justify-center p-3 space-x-4 text-white duration-300 create_button hover:shadow-lg"
      type="submit">
      <span>Checkout ({{formatCurrency(totalCost, 'NGN')}})</span>
    </button>
    }

    <!-- <span [ngClass]="{'text-green-500' : cartCount>0}">Adesina</span> -->
  </div>
</div>