import { Component, inject, Input, PLATFORM_ID } from '@angular/core';
import { NgxEchartsDirective, NgxEchartsModule } from 'ngx-echarts';
import { isPlatformBrowser } from '@angular/common';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [NgxEchartsDirective],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.css',
  providers: []  
})
export class BarChartComponent {
  private platformId = inject(PLATFORM_ID);
  isBrowser = isPlatformBrowser(this.platformId);
  @Input() chartColor: string = '#580c8b';

chartOption: EChartsOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    left: '0%',
    right: '0%',
    bottom: '5%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisTick: {
      alignWithLabel: true
    }
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      name: 'Direct',
      type: 'bar',
      barWidth: '40%',
      data: [10, 52, 200, 334, 390, 330, 220],
      itemStyle: {
        color: this.chartColor
      }
    },
  ],
};

}
