import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-password-field',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  templateUrl: './password-field.component.html',
  styleUrl: './password-field.component.css'
})
export class PasswordFieldComponent {
  @Input() passwordControl: FormControl = new FormControl('');
  
  showPassword: boolean = false;
  private actualPassword: string = '';

toggleVisibility() {
  this.showPassword = !this.showPassword;
  
}
  @Input() placeholder : string = 'Enter your password';
  @Input() inputId : string = '';
  @Input() submitted : boolean = false;
  @Output() passwordFocus = new EventEmitter<void>();
  @Output() passwordBlur = new EventEmitter<void>();

  onPasswordFocus() {
    this.passwordFocus.emit();
  }

  onPasswordBlur() {
    this.passwordBlur.emit();
  }
      
}
