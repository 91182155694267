<div class="p-4 h-full bg-white rounded-lg border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
  <div class="flex flex-col space-y-4 w-full">
    <div class="flex justify-between items-center">
      <span class="pi pi-{{icon}} text-2xl text-vendblocpurple"></span>
      <span class="text-sm text-gray-500">{{ cardName }}</span>
    </div>
    <div class="flex flex-col space-y-2">
      <span class="text-3xl font-bold text-vendblocblue">{{cardCount}}</span>
      <div class="flex items-center space-x-2">
        <span class="text-sm text-green-500">+2.5%</span>
        <span class="text-xs text-gray-400">vs last month</span>
      </div>
    </div>
  </div>
</div>