import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-delivery-option',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './delivery-option.component.html',
  styleUrl: './delivery-option.component.css'
})
export class DeliveryOptionComponent {
  @Input() selectedDeliveryMode: number = 0;
  @Input() enableDelivery: boolean | undefined = false;
  @Input() uniqueId: string = '';
  @Output() selectedDeliveryModeEmitter = new EventEmitter<number>();

  deliveryModeChanged(): void {
      this.selectedDeliveryModeEmitter.emit(this.selectedDeliveryMode);
  }
}