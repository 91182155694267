<div class="form_div">
  <label [for]="inputId">Password</label>
  <div class="relative flex items-center">
    <input
      class="w-full p-3 border rounded-md pr-10"
      [formControl]="passwordControl"
      [type]="showPassword ? 'text' : 'password'"
      [id]="inputId"
      placeholder="Enter your password"
      (focus)="onPasswordFocus()"
      (blur)="onPasswordBlur()"
    />
    <span 
      class="absolute right-3 cursor-pointer text-gray-500 hover:text-vendblocpurple"
      (click)="toggleVisibility()">
      <i class="pi" [ngClass]="showPassword ? 'pi-eye' : 'pi-eye-slash'"></i>
    </span>
  </div>

  <div class="error-container">
    @if(passwordControl!.invalid && (passwordControl!.dirty || passwordControl!.touched || submitted)){
      <div class="flex flex-col items-start text-xs italic text-red-400">
        @if(passwordControl!.errors?.['required']){
          <span>Required</span> 
        }
        @if(passwordControl!.errors?.['minlength']){
          <span>Enter at least {{passwordControl!.errors?.['minlength'].requiredLength}} characters</span>
        }
        @if(passwordControl!.errors?.['pattern']){
          <span>Invalid password format</span>
        }
        @if(passwordControl!.errors && !passwordControl!.errors['required'] && !passwordControl!.errors['minlength'] && !passwordControl!.errors['pattern']){
          <span>Invalid password</span>
        }
      </div>
    }
  </div>
</div>
