import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Inventory, Outlet } from '@models/index';
import { CartItemComponent } from "../../cart-item/cart-item.component";
import { DeliveryOptionComponent } from "../../delivery-option/delivery-option.component";
import { formatCurrency } from '../../../utils/shared/shared';

@Component({
  selector: 'app-cart-card',
  standalone: true,
  imports: [CartItemComponent, DeliveryOptionComponent],
  templateUrl: './cart-card.component.html',
  styleUrl: './cart-card.component.css'
})
export class CartCardComponent{
  @Input() cart: Inventory[][] = [];
  @Input() outlet: Outlet | undefined = undefined;
  @Input() cartCount: number = 0;
  @Input() deliveryLocation: string = '';
  @Input() deliveryFee: number = 0; 
  @Input() serviceCharge: number = 0;
  @Input() totalCost = 0;
  @Input() ordersCost = 0;
  @Input() selectedDeliveryMode: number = 0;
  ordersCostString = '';
  @Input() uniqueId = '';
  
  @Output() updateCartQuantity = new EventEmitter<{cartItem: Inventory, increment: boolean}>();
  @Output() removeFromCart = new EventEmitter<{cartItem: Inventory, index: number}>();
  @Output() deliveryModeChanged = new EventEmitter<number>();
  @Output() clearCart = new EventEmitter<void>();
  @Output() newOrder = new EventEmitter<void>();
  @Output() checkout = new EventEmitter<void>();

  checkoutEvent(){
    this.checkout.emit();
  }

  formatCurrency = formatCurrency

  newOrderEvent(){
    this.newOrder.emit();
  }

  clearCartEvent(){
    this.clearCart.emit();
  }

  deliveryModeChangedEvent(selectedDeliveryMode: number){
    this.selectedDeliveryMode = selectedDeliveryMode;
    this.deliveryModeChanged.emit(selectedDeliveryMode);
  }

  updateCartQuantityEvent(event: {cartItem: Inventory, increment: boolean}){
    this.updateCartQuantity.emit(event);
  }

  removeFromCartEvent(cartItem: Inventory, index: number){
    this.removeFromCart.emit({cartItem, index});
  }
}
