import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@data-access/services';
import { User } from '@models/index';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { first } from 'rxjs';
import { DockModule } from 'primeng/dock';
import { MenuItem } from 'primeng/api/menuitem';
import { TooltipModule } from 'primeng/tooltip';
import { AdminLayoutComponent } from "../layouts/admin-layout/admin-layout.component";
import { StatCardComponent } from "../stat-card/stat-card.component";
import { BarChartComponent, LineChartComponent, BreadcrumbComponent } from '../shared/index';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SidebarModule, ButtonModule, DockModule, TooltipModule, StatCardComponent, BreadcrumbComponent, BarChartComponent, AdminLayoutComponent, LineChartComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  items: MenuItem[] | undefined;

  user: User | null = null;
  sidebarVisible: boolean = false;
  breadcrumbItems: MenuItem[] = [
    { label: 'Dashboard', routerLink: '/dashboard' },
    { label: 'Overview' }
  ];

  homeItem: MenuItem = {
    icon: 'pi pi-home',
    routerLink: '/'
  };

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.authService.user$.pipe(first()).subscribe((response) => {
      this.user = response;
    });
  }

  navigate(event: Event, link: string) {
    event.preventDefault();
    if (link !== '') {
      this.router.navigate([link]);
    }
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['login']);
  }
  
}
