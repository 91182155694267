<app-admin-layout>
    <app-breadcrumb 
      [items]="breadcrumbItems"
      [home]="homeItem"
    ></app-breadcrumb>
    <app-outlet-menubar></app-outlet-menubar>

    <div class="mt-3 md:mt-5">
        <ng-content></ng-content>
    </div>
</app-admin-layout>